import convertFormdataToJsonObject from '../../util/objects';
import {
    PriceListExtraCost,
    PriceListPriceInformation
} from '../../collections/price-list/PriceListCollection';
import { ExtraCostsListRendererProps } from '../../modules/app/ExtraCostsListRenderer';
import TranslationManager from '../../common/TranslationManager';
import ExtraCostPaymentType from '../../enums';
import PriceListModel, { type PriceListApiSearchParams } from './PriceListModel';

type DetailPriceListFormDataType = {
    'price_calculation[arrivalDate]': string;
    'price_calculation[departureDate]': string;
    'price_calculation[adults]': number;
    'price_calculation[children]': number;
    'price_calculation[babies]': number;
    'price_calculation[pets]': number;
};

export default class DetailPriceListModel implements PriceListModel {
    public static parseToSearchParams(formData: FormData): URLSearchParams {
        const data = convertFormdataToJsonObject(formData) as DetailPriceListFormDataType;

        const params: PriceListApiSearchParams = {
            arrivalDate: data['price_calculation[arrivalDate]'] ?? '',
            departureDate: data['price_calculation[departureDate]'] ?? '',
            numberOfAdults: (data['price_calculation[adults]'] ?? 0).toString(),
            numberOfChildren: (data['price_calculation[children]'] ?? 0).toString(),
            numberOfBabies: (data['price_calculation[babies]'] ?? 0).toString(),
            numberOfPets: (data['price_calculation[pets]'] ?? 0).toString(),
            insurance: '0'
        };

        return new URLSearchParams(params);
    }

    public static async parseToTemplateParams(
        data: PriceListPriceInformation
    ): Promise<ExtraCostsListRendererProps> {
        const translationManager = await TranslationManager.getInstance();

        const { serviceFee, extraCosts, includedCosts } = data;
        const afterStayCosts: PriceListExtraCost[] = [];
        const optionalCosts: PriceListExtraCost[] = [];

        for (const extraCost of extraCosts) {
            if (extraCost.optional) {
                optionalCosts.push(extraCost);
                continue;
            }

            switch (extraCost.paymentType) {
                case ExtraCostPaymentType.AFTER_STAY_COSTS:
                    afterStayCosts.push(extraCost);
                    break;
                default:
                    includedCosts.push(extraCost.name);
            }
        }

        return {
            serviceFee,
            includedCosts: includedCosts.map((includedCost) =>
                translationManager.translate(includedCost)
            ),
            afterStayCosts: afterStayCosts.map((extraCost) => ({
                ...extraCost,
                name: translationManager.translate(extraCost.name),
                priceType: translationManager.translate(extraCost.priceType)
            })),
            optionalCosts: optionalCosts.map((optionalCost) => ({
                ...optionalCost,
                name: translationManager.translate(optionalCost.name),
                priceType: translationManager.translate(optionalCost.priceType)
            }))
        };
    }
}
