var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["@naturehouse/design-system/components/molecules/info-tip/InfoTip.macro.njk"] = require( "@naturehouse/design-system/components/molecules/info-tip/InfoTip.macro.njk" );
dependencies["@naturehouse/design-system/components/protons/icon/Icon.macro.njk"] = require( "@naturehouse/design-system/components/protons/icon/Icon.macro.njk" );




var shim = require("/builds/natuurhuisje/nature.house/symfony/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["templates/website/detail/partials/_price-list.html.njk"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("@naturehouse/design-system/components/molecules/info-tip/InfoTip.macro.njk", false, "templates/website/detail/partials/_price-list.html.njk", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("infoTip", t_1);
output += "\n";
env.getTemplate("@naturehouse/design-system/components/protons/icon/Icon.macro.njk", false, "templates/website/detail/partials/_price-list.html.njk", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
context.setVariable("iconTemplate", t_4);
output += "\n\n<table class=\"nh-table nh-table--margin\">\n    <tbody>\n        <tr>\n            <th>\n                ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"total"), env.opts.autoescape);
output += "\n                <button type=\"button\" class=\"nh-info-tip\" data-role=\"extra-costs-dialog-toggle\">\n                    ";
output += runtime.suppressValue(env.getFilter("safe").call(context, (lineno = 9, colno = 42, runtime.callWrap(runtime.memberLookup((t_4),"render"), "iconTemplate[\"render\"]", context, [{"name": "info","size": "0.75"}]))), env.opts.autoescape);
output += "\n                </button>\n            </th>\n            <td>\n                ";
if(runtime.contextOrFrameLookup(context, frame, "promotionalDiscount") > 0) {
output += "\n                    <del>";
output += runtime.suppressValue(env.getFilter("format_currency").call(context, (runtime.contextOrFrameLookup(context, frame, "totalStayAmountWithoutDiscount") / 100),"EUR"), env.opts.autoescape);
output += "</del>\n                    <ins data-total-price=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "totalStayAmount") / 100, env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(env.getFilter("format_currency").call(context, ((runtime.contextOrFrameLookup(context, frame, "totalStayAmount")) / 100),"EUR"), env.opts.autoescape);
output += "</ins>\n                ";
;
}
else {
output += "\n                    <span data-total-price=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "totalStayAmount") / 100, env.opts.autoescape);
output += "\" data-pw=\"total-price\">";
output += runtime.suppressValue(env.getFilter("format_currency").call(context, (runtime.contextOrFrameLookup(context, frame, "totalStayAmount") / 100),"EUR"), env.opts.autoescape);
output += "</span>\n                ";
;
}
output += "\n            </td>\n        </tr>\n\n        <tr>\n            <td>\n                <span class=\"text-gray\">\n                    ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "translations")),"includingExtraCosts"), env.opts.autoescape);
output += "\n                </span>\n            </td>\n            <td></td>\n        </tr>\n    </tbody>\n</table>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["templates/website/detail/partials/_price-list.html.njk"] , dependencies)