import Dialog from '@naturehouse/design-system/components/molecules/dialog/Dialog';

type DialogHandlerProps = {
    buttonSelector: string;
    dialogSelector: string;
};

export default class DialogHandler {
    readonly #dialogToggleButton: HTMLElement | null;

    public readonly dialog: Dialog | null;

    public constructor({ buttonSelector, dialogSelector }: DialogHandlerProps) {
        this.#dialogToggleButton = document.querySelector(buttonSelector);
        this.dialog = document.querySelector(dialogSelector);
    }

    public initialize(): void {
        if (!this.#dialogToggleButton || !this.dialog) {
            return;
        }

        this.#dialogToggleButton.addEventListener('click', this.#openDialog);
    }

    public destroy(): void {
        if (!this.#dialogToggleButton || !this.dialog) {
            return;
        }

        this.#dialogToggleButton.removeEventListener('click', this.#openDialog);
    }

    readonly #openDialog = (): void => {
        this.dialog?.showModal();
    };
}
