var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["@naturehouse/design-system/components/protons/icon/Icon.macro.njk"] = require( "@naturehouse/design-system/components/protons/icon/Icon.macro.njk" );
dependencies["@naturehouse/design-system/components/molecules/dialog/partials/confirmation-footer.html.njk"] = require( "@naturehouse/design-system/components/molecules/dialog/partials/confirmation-footer.html.njk" );
dependencies["@naturehouse/design-system/components/molecules/dialog/partials/footer.html.njk"] = require( "@naturehouse/design-system/components/molecules/dialog/partials/footer.html.njk" );
dependencies["@naturehouse/design-system/components/molecules/dialog/partials/old-footer.html.njk"] = require( "@naturehouse/design-system/components/molecules/dialog/partials/old-footer.html.njk" );




var shim = require("/builds/natuurhuisje/nature.house/symfony/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["design-system/v3/components/molecules/dialog/Dialog.html.njk"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("@naturehouse/design-system/components/protons/icon/Icon.macro.njk", false, "design-system/v3/components/molecules/dialog/Dialog.html.njk", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("iconTemplate", t_1);
var t_4;
t_4 = env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "classes"),[]);
frame.set("classes", t_4, true);
if(frame.topLevel) {
context.setVariable("classes", t_4);
}
if(frame.topLevel) {
context.addExport("classes", t_4);
}
var t_5;
t_5 = (env.getTest("defined").call(context, runtime.contextOrFrameLookup(context, frame, "variant")) === true && runtime.contextOrFrameLookup(context, frame, "variant") == "confirmation");
frame.set("isConfirmation", t_5, true);
if(frame.topLevel) {
context.setVariable("isConfirmation", t_5);
}
if(frame.topLevel) {
context.addExport("isConfirmation", t_5);
}
output += "\n";
var t_6;
t_6 = (env.getTest("defined").call(context, runtime.contextOrFrameLookup(context, frame, "variant")) === true && runtime.contextOrFrameLookup(context, frame, "variant") == "attached");
frame.set("isAttached", t_6, true);
if(frame.topLevel) {
context.setVariable("isAttached", t_6);
}
if(frame.topLevel) {
context.addExport("isAttached", t_6);
}
output += "\n\n";
var t_7;
t_7 = "true";
frame.set("isDismissable", t_7, true);
if(frame.topLevel) {
context.setVariable("isDismissable", t_7);
}
if(frame.topLevel) {
context.addExport("isDismissable", t_7);
}
if(env.getTest("defined").call(context, runtime.contextOrFrameLookup(context, frame, "dismissable")) === true && runtime.contextOrFrameLookup(context, frame, "dismissable") == false) {
var t_8;
t_8 = "false";
frame.set("isDismissable", t_8, true);
if(frame.topLevel) {
context.setVariable("isDismissable", t_8);
}
if(frame.topLevel) {
context.addExport("isDismissable", t_8);
}
output += "\n";
;
}
output += "\n\n";
var t_9;
t_9 = !runtime.contextOrFrameLookup(context, frame, "isAttached");
frame.set("showHeader", t_9, true);
if(frame.topLevel) {
context.setVariable("showHeader", t_9);
}
if(frame.topLevel) {
context.addExport("showHeader", t_9);
}
output += "\n";
var t_10;
t_10 = !runtime.contextOrFrameLookup(context, frame, "isAttached") && ((!env.getTest("defined").call(context, runtime.contextOrFrameLookup(context, frame, "hideFooter")) === true) || env.getTest("defined").call(context, runtime.contextOrFrameLookup(context, frame, "hideFooter")) === true && !runtime.contextOrFrameLookup(context, frame, "hideFooter")) || runtime.contextOrFrameLookup(context, frame, "isConfirmation");
frame.set("showFooter", t_10, true);
if(frame.topLevel) {
context.setVariable("showFooter", t_10);
}
if(frame.topLevel) {
context.addExport("showFooter", t_10);
}
output += "\n\n<";
output += runtime.suppressValue(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "is"),"nh-dialog"), env.opts.autoescape);
output += "\n    class=\"";
output += runtime.suppressValue(env.getFilter("join").call(context, runtime.contextOrFrameLookup(context, frame, "classes")," "), env.opts.autoescape);
output += "\"";
if(env.getTest("defined").call(context, runtime.contextOrFrameLookup(context, frame, "id")) === true && runtime.contextOrFrameLookup(context, frame, "id")) {
output += " id=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "id"), env.opts.autoescape);
output += "\" ";
;
}
if(env.getTest("defined").call(context, runtime.contextOrFrameLookup(context, frame, "variant")) === true && runtime.contextOrFrameLookup(context, frame, "variant")) {
output += " variant=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "variant"), env.opts.autoescape);
output += "\" ";
;
}
frame = frame.push();
var t_13 = env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "data"));
if(t_13) {t_13 = runtime.fromIterator(t_13);
var t_11;
if(runtime.isArray(t_13)) {
var t_12 = t_13.length;
for(t_11=0; t_11 < t_13.length; t_11++) {
var t_14 = t_13[t_11][0];
frame.set("[object Object]", t_13[t_11][0]);
var t_15 = t_13[t_11][1];
frame.set("[object Object]", t_13[t_11][1]);
frame.set("loop.index", t_11 + 1);
frame.set("loop.index0", t_11);
frame.set("loop.revindex", t_12 - t_11);
frame.set("loop.revindex0", t_12 - t_11 - 1);
frame.set("loop.first", t_11 === 0);
frame.set("loop.last", t_11 === t_12 - 1);
frame.set("loop.length", t_12);
output += " ";
output += runtime.suppressValue(t_14, env.opts.autoescape);
if(t_15) {
output += "=\"";
output += runtime.suppressValue(t_15, env.opts.autoescape);
output += "\" ";
;
}
;
}
} else {
t_11 = -1;
var t_12 = runtime.keys(t_13).length;
for(var t_16 in t_13) {
t_11++;
var t_17 = t_13[t_16];
frame.set("name", t_16);
frame.set("value", t_17);
frame.set("loop.index", t_11 + 1);
frame.set("loop.index0", t_11);
frame.set("loop.revindex", t_12 - t_11);
frame.set("loop.revindex0", t_12 - t_11 - 1);
frame.set("loop.first", t_11 === 0);
frame.set("loop.last", t_11 === t_12 - 1);
frame.set("loop.length", t_12);
output += " ";
output += runtime.suppressValue(t_16, env.opts.autoescape);
if(t_17) {
output += "=\"";
output += runtime.suppressValue(t_17, env.opts.autoescape);
output += "\" ";
;
}
;
}
}
}
frame = frame.pop();
if(!env.getTest("defined").call(context, runtime.contextOrFrameLookup(context, frame, "clickOutside")) === true || (env.getTest("defined").call(context, runtime.contextOrFrameLookup(context, frame, "clickOutside")) === true && runtime.contextOrFrameLookup(context, frame, "clickOutside"))) {
output += " click-outside ";
;
}
output += "dismissable=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "isDismissable"), env.opts.autoescape);
output += "\"";
if(env.getTest("defined").call(context, runtime.contextOrFrameLookup(context, frame, "placement")) === true && runtime.contextOrFrameLookup(context, frame, "placement")) {
output += " placement=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "placement"), env.opts.autoescape);
output += "\" ";
;
}
if(env.getTest("defined").call(context, runtime.contextOrFrameLookup(context, frame, "boundElementId")) === true && runtime.contextOrFrameLookup(context, frame, "boundElementId")) {
output += " bound-element-id=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "boundElementId"), env.opts.autoescape);
output += "\" ";
;
}
output += ">\n    <dialog class=\"nh-dialog\">\n        ";
if(runtime.contextOrFrameLookup(context, frame, "showHeader")) {
output += "\n            <header class=\"nh-dialog__header\">\n                ";
if(env.getTest("defined").call(context, runtime.contextOrFrameLookup(context, frame, "backButton")) === true && runtime.contextOrFrameLookup(context, frame, "backButton")) {
output += "\n                    <div class=\"nh-dialog__back\">\n                        <button\n                            class=\"nh-button nh-button--minimal\"\n                            is=\"nh-button\"\n                            variant=\"minimal\"\n                            size=\"small\"\n                            data-role=\"back\"\n                        >\n                            ";
output += runtime.suppressValue((lineno = 37, colno = 50, runtime.callWrap(runtime.memberLookup((t_1),"render"), "iconTemplate[\"render\"]", context, [{"name": "direction-left","size": 1.5}])), env.opts.autoescape);
output += "\n                        </button>\n                    </div>\n                ";
;
}
output += "\n\n                <span class=\"nh-dialog__title\" data-role=\"title\">";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.contextOrFrameLookup(context, frame, "title")), env.opts.autoescape);
output += "</span>\n\n                ";
if(env.getTest("defined").call(context, runtime.contextOrFrameLookup(context, frame, "closeButton")) === true && runtime.contextOrFrameLookup(context, frame, "closeButton")) {
output += "\n                    <div class=\"nh-dialog__close\">\n                        <button\n                            class=\"nh-button nh-button--minimal\"\n                            is=\"nh-button\"\n                            variant=\"minimal\"\n                            size=\"small\"\n                            data-role=\"close\"\n                        >\n                            ";
output += runtime.suppressValue((lineno = 56, colno = 50, runtime.callWrap(runtime.memberLookup((t_1),"render"), "iconTemplate[\"render\"]", context, [{"name": "close","size": 1.5}])), env.opts.autoescape);
output += "\n                        </button>\n                    </div>\n                ";
;
}
output += "\n            </header>\n        ";
;
}
output += "\n\n        <div class=\"nh-dialog__content\" data-role=\"content\">\n            ";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.contextOrFrameLookup(context, frame, "content")), env.opts.autoescape);
output += "\n        </div>\n\n        ";
if(runtime.contextOrFrameLookup(context, frame, "showFooter") == true) {
output += "\n            ";
if(env.getTest("defined").call(context, runtime.contextOrFrameLookup(context, frame, "footer")) === true) {
output += "\n                ";
if(runtime.contextOrFrameLookup(context, frame, "isConfirmation")) {
output += "\n                    ";
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("@naturehouse/design-system/components/molecules/dialog/partials/confirmation-footer.html.njk", false, "design-system/v3/components/molecules/dialog/Dialog.html.njk", false, function(t_19,t_18) {
if(t_19) { cb(t_19); return; }
callback(null,t_18);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_21,t_20) {
if(t_21) { cb(t_21); return; }
callback(null,t_20);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
output += "\n                ";
});
}
output += "\n                ";
if(!runtime.contextOrFrameLookup(context, frame, "isConfirmation")) {
output += "\n                    ";
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("@naturehouse/design-system/components/molecules/dialog/partials/footer.html.njk", false, "design-system/v3/components/molecules/dialog/Dialog.html.njk", false, function(t_23,t_22) {
if(t_23) { cb(t_23); return; }
callback(null,t_22);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_25,t_24) {
if(t_25) { cb(t_25); return; }
callback(null,t_24);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
output += "\n                ";
});
}
output += "\n            ";
;
}
else {
output += "\n                ";
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate("@naturehouse/design-system/components/molecules/dialog/partials/old-footer.html.njk", false, "design-system/v3/components/molecules/dialog/Dialog.html.njk", false, function(t_27,t_26) {
if(t_27) { cb(t_27); return; }
callback(null,t_26);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_29,t_28) {
if(t_29) { cb(t_29); return; }
callback(null,t_28);});
});
tasks.push(
function(result, callback){
output += result;
callback(null);
});
env.waterfall(tasks, function(){
output += "\n            ";
});
}
output += "\n        ";
;
}
output += "\n    </dialog>\n</";
output += runtime.suppressValue(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "is"),"nh-dialog"), env.opts.autoescape);
output += ">\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["design-system/v3/components/molecules/dialog/Dialog.html.njk"] , dependencies)